<template>
  <div class="container min-width">
    <div class="top-box">
      <div class="top-top">
        <div class="top-title-box">
          <span class="title-lab">
            本月营业额
          </span>
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">仅展示当月数据</div>
            <i class="el-icon-question" style="color:#b6b6b6;"></i>
          </el-tooltip>
        </div>
        <el-card style="margin-top:10px">
          <ve-histogram :data="chartData" height="302px" :extend="chartExtend" :grid="grid"></ve-histogram>
        </el-card>
      </div>
      <div class="top-bottom">
        <div class="top-title-box">
          <span class="title-lab">
            本月绩效
          </span>
          <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">默认汇总当月数据<br />时间范围查询后根据所选范围汇总</div>
            <i class="el-icon-question" style="color:#b6b6b6;"></i>
          </el-tooltip>
        </div>
        <div class="order-card-box-left">
          <div class="divflexxb">
            <order-card title="油枪金额(¥)" :bodyData="cardData.gunAmount" :isMoney="true"></order-card>
            <order-card title="合同金额(¥)" :bodyData="cardData.salesAmount" :isMoney="true"></order-card>
            <order-card title="手续费(1%)" :bodyData="cardData.fee" :isMoney="true"></order-card>
            <order-card title="到账金额(¥)" :bodyData="cardData.arriveAmount" :isMoney="true"></order-card>
            <order-card title="提现金额(¥)" :bodyData="cardData.withdrawAmount" :isMoney="true"></order-card>
            <order-card title="油枪-到账金额(¥)" :bodyData="cardData.gunArriveAmount" :isMoney="true"></order-card>
          </div>
        </div>
      </div>

    </div>

    <div style="margin-top: 20px">
      <span class="title-lab">订单查询</span>
      <div class="search-box">
        <el-form :model="form" status-icon ref="form" label-width="80px" class="demo-form" inline>
          <el-form-item label="订单编号" prop="tradeNo">
            <el-input type="text" v-model="form.tradeNo" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input type="text" v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="微信昵称" prop="nickname">
            <el-input type="text" v-model="form.nickname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker v-model="form.startTime" type="datetime" placeholder="选择开始时间" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="endTime">
            <el-date-picker v-model="form.endTime" type="datetime" placeholder="选择结束时间" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="selfQuery">查询</el-button>
            <el-button icon="el-icon-refresh-right" @click="restQuery">重置</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div class="body-list">
        <el-table :data="tableData" style="width: 100%;margin-top: 20px;" border v-loading="loading" :row-class-name="tableRowClassName">
          <el-table-column prop="id" label="ID" width="80" align="center" />
          <el-table-column prop="tradeNo" label="订单编号" width="150" align="center" />
          <el-table-column prop="phone" label="手机" width="100" align="center" />
          <el-table-column prop="nickname" label="微信昵称" width="100" align="center" />
          <el-table-column prop="orderMoney" label="油枪金额" align="center">
            <template slot-scope="scope">
              {{scope.row.orderMoney |Money}}
            </template>
          </el-table-column>
          <el-table-column prop="contractMoney" label="合同金额" align="center">
            <template slot-scope="scope">
              {{scope.row.contractMoney |Money}}
            </template>
          </el-table-column>
          <el-table-column prop="gasFeeMoney" label="手续费" align="center">
            <template slot-scope="scope">
              {{scope.row.gasFeeMoney |Money}}
            </template>
          </el-table-column>
          <el-table-column prop="gasMoney" label="到账金额" align="center">
            <template slot-scope="scope">
              {{scope.row.gasMoney |Money}}
            </template>
          </el-table-column>
          <el-table-column label="油枪号" width="80px" align="center">
            <template slot-scope="scope">
              <b style="font-size:16px;">{{scope.row.gunNum}}号</b>/{{scope.row.goodsName}}
            </template>
          </el-table-column>
          <el-table-column prop="gasName" label="加油站名" width="180" align="center" />
          <!-- <el-table-column prop="gasLatitude" label="经/纬度" width="80" align="center" >
        <template slot-scope="scope">
            经度：{{scope.row.gasLatitude}}
            纬度：{{scope.row.gasLongitude}}
          </template>
        </el-table-column> -->
          <el-table-column prop="gasImg" label="加油站门店图片" width="120" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.gasImg" alt="暂无图片" class="img-style">
            </template>
          </el-table-column>
          <!-- <el-table-column prop="gasAddress" label="加油站地址" width="220" align="center" /> -->
          <el-table-column prop="createTime" label="时间" width="140" align="center" />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderList, salesStatistics } from "../../api/order";
import { summary } from "../../api/user";

import OrderCard from '@/components/common/orderCard.vue'

export default {
  name: "OrderList",
  components: { OrderCard },

  data() {
    return {
      loading: false,
      queryParams: {
        pageNo: 1,
        pageSize: 10000,
        status: 2,
        gasId: JSON.parse(localStorage.getItem('userInfo')).gasId,
        startTime: this.getSameMonth()
      },
      cardData: {},
      tableData: [],
      form: {

      },
      rules: [],
      chartExtend: {
        // x轴字体斜显示
        xAxis: {
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },
        series: {
          barWidth: 20,
          label: {
            normal: {
              show: true,
              position: 'top',
              textStyle: {
                color: 'black'
              }
            }
          }
        },
      },
      grid: {
        top: 40,
        bottom: 10
      },

      chartData: {
        columns: ["日期", "营业额"],
        rows: [],
      },
    }
  },
  created() {
    this.getList(this.queryParams)
    this.getSalesStatistics(this.queryParams)
    this.getSummary()
  },
  methods: {

    // 表单重置
    reset() {
      this.form = {
        pageNo: 1,
        pageSize: 10000,
        status: 2,
        gasId: JSON.parse(localStorage.getItem('userInfo')).gasId,
      };
      this.resetForm("form");
    },

    // 查询订单
    getList(params) {
      this.loading = true
      getOrderList(params).then((res) => {
        this.tableData = res.data
        this.loading = false
      })
    },

    // 查询销售额统计
    getSalesStatistics(params) {
      salesStatistics(params).then((res) => {
        this.cardData = res.data
      })
    },

    // 获取30天营业额图表数据
    getSummary() {
      summary().then((res) => {
        var ress = [
          { "date": "2021-11-01", "amount": 53600 },
          { "date": "2021-11-02", "amount": 2989 },
          { "date": "2021-11-03", "amount": 19989 },
          { "date": "2021-11-04", "amount": 30989 },
          { "date": "2021-11-05", "amount": 30989 },
          { "date": "2021-11-06", "amount": 39989 },
          { "date": "2021-11-07", "amount": 54989 },
          { "date": "2021-11-08", "amount": 9989 },
          { "date": "2021-11-09", "amount": 39989 },
          { "date": "2021-11-10", "amount": 31989 },
          { "date": "2021-11-11", "amount": 30989 },
          { "date": "2021-11-12", "amount": 54565 },
          { "date": "2021-11-13", "amount": 9989 },
          { "date": "2021-11-14", "amount": 19989 },
          { "date": "2021-11-15", "amount": 55214 },
          { "date": "2021-11-16", "amount": 30989 },
          { "date": "2021-11-17", "amount": 30989 },
          { "date": "2021-11-18", "amount": 30998 },
          { "date": "2021-11-19", "amount": 36989 },
          { "date": "2021-11-20", "amount": 54543 },
          { "date": "2021-11-21", "amount": 30989 },
          { "date": "2021-11-22", "amount": 1989 },
          { "date": "2021-11-23", "amount": 3989 },
          { "date": "2021-11-24", "amount": 5856 },
          { "date": "2021-11-25", "amount": 50999 },
          { "date": "2021-11-26", "amount": 20999 },
          { "date": "2021-11-27", "amount": 19989 },
          { "date": "2021-11-28", "amount": 49989 },
          { "date": "2021-11-29", "amount": 4089 },
          { "date": "2021-11-30", "amount": 39989 },
          { "date": "2021-11-31", "amount": 9989 }
        ]
        res.data.monthOrderSummaries.forEach((rows) => {
          // ress.forEach((rows) => {
          for (const key in rows) {
            if (key === "date") rows['日期'] = rows[key]
            if (key === "amount") rows['营业额'] = rows[key]
            rows['营业额'] = rows['营业额'] / 100
          }
        })
        this.chartData.rows = res.data.monthOrderSummaries
        // this.chartData.rows = ress
      })
    },

    // 自定义查询
    selfQuery() {
      this.getList(this.form)
      if (this.form.startTime !== undefined || this.form.endTime !== undefined) this.getSalesStatistics(this.form)
    },

    // 重置
    restQuery() {
      this.reset()
      this.getList(this.queryParams)
      this.getSalesStatistics(this.queryParams)
    },
    // 自定义斑马纹
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'success-row';
      }
      return '';
    },
  }

}
</script>

<style lang="scss" scoped>
.container{
  padding-top: 0 !important;
}
.img-style {
  height: 50px;
  object-fit: contain;
}
.body-list {
  margin-top: 20px;
}

.order-card-box-top {
  width: 100%;
}
.order-card-box-bottom {
  width: 100%;
  height: 360px;
  line-height: 360px;
}

.title-lab {
  color: #56585c;
  font-size: 20px;
}
.top-box {
  width: 100%;
}

.top-bottom{
  margin-top: 10px;
}
</style>